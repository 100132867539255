.nav-link{
    font-size: 0.8rem !important;
    text-transform: uppercase;
    padding: 1rem 1rem !important;
    margin: 0;
    font-weight: 700 !important;
    letter-spacing: 2px;
    color: #8e8e8e;
}

.navbar{
    height:10vh;
}

.navbar-brand{
    font-weight: 900;
    font-size: 1.3rem !important;
    color:black;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.hsr-toggle{
    background-color: transparent;
    border:none;
    font-size: 1.7rem;
}

.offcanvas{
    width: 70% !important;
    background:black;
    .navbar-brand{
        padding-left:0rem !important;
        font-size: 1rem !important;
    }

    .btn-close{
        font-size: 0.8rem !important;
    }

    ul{
        list-style: none;
        padding: 0;

        .nav-link{
            padding: 0.75rem 0 !important;
        }
    }
}

@media (max-width:548px) {
    .navbar-brand{
        padding-left: 1rem;
    }

    .hsr-toggle{
        margin-right: 1rem;
    }
}