a{
    text-decoration: none;
}

.hsr-about-content{
    font-weight: 500;
    color:gray;
}

.hsr-why-choose{
    background: #f3f5f7;
    h1{
        font-size: 2rem;
        font-weight: 800;
        text-transform: uppercase;
        text-align: center;
    }

    .hsr-why-card{
        margin-top:1rem;
        border-radius: 5px;
        // border: 2px solid var(--theme-color);
        border:none;

        .hsr-icons{
            text-align: center;
            font-size: 5rem;
            margin: 1rem auto;
            color:var(--theme-orange);
        }

        h3{
            margin:0.5rem auto;
            font-size: 1rem;
            font-weight: 700;
            text-align: center;
        }

        p{
            margin:0.5rem auto;
            font-size: 0.8rem;
            text-align: justify;
            font-weight: 500;
        }
    }
}