.hsr-theme-button{
    background: var(--theme-color);
    color:white;
    min-width: 12rem;
    padding: 1rem 2rem;
    border:none;
    font-weight: 700;
    letter-spacing: 3px;
    text-transform: uppercase;
    font-size: 0.9rem;
}

@media (max-width:548px) {
    .hsr-theme-button{
        background: var(--theme-color);
        color:white;
        min-width: 10rem;
        padding: 0.75rem 1rem;
        border:none;
        font-weight: 700;
        letter-spacing: 3px;
        text-transform: uppercase;
        font-size: 0.9rem;
    }
}