.hsr-footer{
    background-color: #212121;
    padding: auto;
    font-family: 'Montserrat';

    h4{
        text-transform: uppercase;
        font-size: 1rem;
        letter-spacing: 2px;
        font-weight: 600;
        color:#fff;
        margin-bottom: 1.5rem;
    }

    p{
        color:#BFBFBF;
        font-size: 13px;
    }

    ul{
        list-style: none;
        padding: 0;
        li{
            color:#BFBFBF;
            padding: 0;
            margin:0.6rem 0;
            font-size: 13px;
            a{
                text-decoration: none;
                color: #BFBFBF;
            }
            a:hover{
                color: var(--theme-orange);
            }
        }
    }
    .hsr-copyright{
        background-color: #161616;
        span{
            color: #BFBFBF;
            font-size: 13px;
            padding: 1rem;
            margin-bottom: 0;
            a{
                color: #BFBFBF;
                text-decoration: none;
                margin-bottom: 0;
            }
        }
    }
}