.hsr-hero-image {
    padding: 0 !important;
    margin-top: 10vh;
    .hsr-overlay {
        height: 90vh;
        background: rgba(0, 0, 0, 0.5);
        width: 100%;

        .hsr-caption {
            display: flex;
            align-items: center;
            height: 90vh;

            .hsr-first-title {
                color: white;
                font-size: 50px;
                letter-spacing: 3px;
            }

            .hsr-second-title {
                color: white;
                font-size: 100px;
                font-weight: 800;
                letter-spacing: 5px;
            }

            .breadcrumb {
                margin-top: 2rem;
                float: none;
            }

            .breadcrumb-item+.breadcrumb-item::before {
                color: white !important;
                padding: 0 1rem;
                font-size: 25px;
                font-weight: 700;
            }

            .breadcrumb-item {
                a {
                    text-decoration: none;
                    color: white;
                    font-size: 25px;
                    font-weight: 700;
                    font-family: 'Montserrat', sans-serif;
                }
            }
        }
    }
}


// Countdown
.hsr-countdown {
    background-image: url('../../assets/images/watch1.jpg');
    min-height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
        font-size: 2rem;
        font-weight: 800;
        text-transform: uppercase;
        letter-spacing: 3px;
        margin-bottom: 3rem;
    }

    .hsr-count-box {
        display: inline-block;
        padding: 2rem;
        background-color: rgba(0, 0, 0, 0.5);
        margin: 1rem;
        min-width: 150px;
        text-align: center;
        color: white;
        border-radius: 5px;

        h1 {
            font-size: 3rem;
            font-weight: 800;
            margin-bottom: 0;
            font-family: 'Montserrat';
        }

        span {
            text-transform: uppercase;
            margin-bottom: 0.5rem;
            font-weight: 500;
        }
    }
}

// About
.hsr-about{
    background-image: url('../../assets/images/office.jpg');
    min-height: 70vh;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 0 !important;
    background-attachment: fixed;
    .hsr-overlay{
        min-height: 70vh;
        background:rgba(0, 0, 0, 0.7);
    }

    h1{
        font-size: 2rem;
        color:white;
        text-align: center;
        font-weight: 800;
        letter-spacing: 3px;
        text-transform: uppercase;
    }

    p{
        font-size: 1rem;
        color:white;
    }
    a {
        color: var(--theme-orange);
        font-weight: 600;
        text-decoration: none;
        text-align: center;
    }
}

// Timelines
.hsr-timelines {
    h3 {
        font-size: 2rem;
        font-weight: 800;
        text-transform: uppercase;
        letter-spacing: 3px;
    }

    p {
        font-size: 0.9rem;
        color: gray;
    }

    a {
        color: var(--theme-orange);
        font-weight: 600;
        text-decoration: none;
        text-align: right;
    }

    .nav-tabs {
        border: none;
    }

    .nav-link,
    .nav-link:hover {
        background: var(--theme-orange);
        color: white;
        text-align: left;
        padding: 0.85rem 1.3rem !important;
        border-radius: 0 !important;
        border: none !important;
        font-family: 'Montserrat', sans-serif;

        .hsr-day {
            font-size: 1rem;
            font-weight: 700;
        }

        .hsr-date {
            font-size: 0.7rem;
            font-weight: 500;
        }
    }

    button.active {
        background: var(--theme-color) !important;
        color: white !important;
        text-align: left;
        padding: 0.85rem 1.3rem !important;
        border-radius: 0 !important;
        border: none !important;
        font-family: 'Montserrat', sans-serif;

        .hsr-day {
            font-size: 1rem;
            font-weight: 700;
        }

        .hsr-date {
            font-size: 0.7rem;
            font-weight: 500;
        }
    }

    .tab-content {
        background: #f7f7f7;
        padding: 2rem 1rem;
    }

    
}

// Event Details
.hsr-details {
    h3 {
        font-size: 2rem;
        font-weight: 800;
        text-transform: uppercase;
        letter-spacing: 3px;
    }

    p {
        font-size: 0.9rem;
        color: gray;
    }

    a {
        color: var(--theme-orange);
        font-weight: 600;
        text-decoration: none;
        text-align: right;
    }

    .hsr-event-details {

        h1 {
            font-size: 1.5rem;
            font-weight: 700;
            border-left: 4px solid var(--theme-color);
            padding-left: 15px;
        }

        .hsr-gap {
            border-left: 4px dashed rgba(167, 37, 16, 0.5);
            padding-left: 20px;
            display: inline-block;
        }

        p {
            border-left: 4px dashed rgba(167, 37, 16, 0.5);
            padding-left: 20px;
            color: #111010;
            font-size: 1rem;
        }

        .hsr-eligibility {
            border-left: 4px dashed rgba(167, 37, 16, 0.5);
            padding-left: 20px;
        }

        ul {
            border-left: 2px dashed rgba(167, 37, 16, 0.5);
            list-style: none;
            margin: 0 0 25px 15px;
            padding: 0;
            padding-left: 15px;

            li {
                position: relative;
                list-style-type: none;
                margin-bottom: 7px;
                word-break: break-word;
                text-indent: -20px;
                padding-left: 20px;

                span {
                    top: -7px;
                    position: relative;
                }
            }


            li::before {
                content: '';
                display: inline-block;
                height: 20px;
                width: 20px;
                border-radius: 50%;
                border: 2px solid rgba(167, 37, 16, 0.5);
                background: white;
                left: -26px;
                position: relative;
            }


        }
    }
}


// Prizzes
.hsr-prizzes{
    background: #F1F1F1;
    min-height: 60vh;

    .hsr-heading{
        font-size: 2rem;
        font-weight: 800;
        text-transform: uppercase;
        letter-spacing: 3px;
        text-align: center;
        padding-top: 5rem;
        margin-bottom: 3rem;
    }
    
    a{
        color: var(--theme-orange);
        font-weight: 600;
        text-decoration: none;
    }

    .hsr-prize-card{
        overflow: hidden;
        border:none;
        border-radius:0;
        display: block;
        .card-header{
            background: #E9E9E9;
            border:0;
            border-radius:0;
            overflow: hidden;
            h3{
                font-size: 1.3rem;
                text-align: center;
                font-weight: 700;
                text-transform: uppercase;
                letter-spacing: 3px;
                margin-bottom: 0;
                font-family: 'Montserrat';
            }
            .hsr-certificate{
                background: var(--theme-orange);
    font-size: 8px;
    color: #fff;
    margin-top: 3px;
    margin-bottom: 0;
    transform: rotate(45deg);
    position: absolute;
    right: -30px;
    top: 20px;
    padding: 5px 26.5px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
            }
        }

        .card-footer{
            background:var(--theme-orange);
            border:0;
            border-radius:0;
            h3{
                font-size: 1.3rem;
                text-align: center;
                font-weight: 700;
                text-transform: uppercase;
                letter-spacing: 3px;
                color:white;
                font-family: 'Montserrat';
                margin-bottom: 0;
            }
        }
    }
}

// Faq
.hsr-faqs{
    h1{
        font-size: 2rem;
        font-weight: 800;
        text-transform: uppercase;
        letter-spacing: 3px;
        text-align: center;
        padding-top: 2rem;
        margin-bottom: 3rem;
    }

    .accordion-button .hsr-minus{
        display: none;
    }

    .accordion-button{
        font-size: 15px;
        color: #8d8d8d;
        font-weight:600 ;
    }

    .hsr-plus,.hsr-minus {
        font-size:30px;
        color: #a8a8a8;
    }
    .accordion-button:not(.collapsed) .me-3{
        color: #f62b0a;
        background: transparent;
    }
    .accordion-button:not(.collapsed) .hsr-plus{
        display: none;
    }
    .accordion-button:not(.collapsed) .hsr-minus{
        display: block;
    }

    .accordion-item{
        border: none;
        border-bottom: 1px solid #ddd;
        border-radius: 0 !important;
    }

    .accordion-button:focus{
        box-shadow:none;
        background: transparent;
    }
}

@media (max-width:548px) {
    .hsr-caption {
        .hsr-first-title {
            font-size: 25px !important;
        }

        .hsr-second-title {
            font-size: 50px !important;
        }

        .breadcrumb {
            margin-top: 1.3rem !important;
        }

        .breadcrumb-item+.breadcrumb-item::before {
            font-size: 0.9rem !important;
        }

        .breadcrumb-item {
            a {
                font-size: 0.9rem !important;
            }
        }
    }

    // Counter
    .hsr-countdown {
        min-height: 60vh !important;
        padding: 3rem 1rem;

        h1 {
            font-size: 1.5rem;
            margin-top: 1rem;
        }

        .hsr-countdown-area {
            margin: auto;
            text-align: center;

            .hsr-count-box {
                margin: 0.5rem;
            }
        }
    }
}