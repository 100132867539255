.hsr-timeline-area{
    .row > *{
        padding: 0!important;
    }
    .hsr-time-head{
        .hsr-day {
            font-size: 1rem;
            font-weight: 700;
            margin-bottom: 0rem;
        }

        .hsr-date {
            font-size: 0.8rem;
            font-weight: 500;
        }

        .hsr-active{
            background: var(--theme-color);
            color: white;
        text-align: left;
        padding: 0.85rem 1.3rem !important;
        border-radius: 0 !important;
        border: none !important;
        font-family: 'Montserrat', sans-serif;
        }

        .hsr-normal{
            background: var(--theme-orange);
        color: white;
        text-align: left;
        padding: 0.85rem 1.3rem !important;
        border-radius: 0 !important;
        border: none !important;
        font-family: 'Montserrat', sans-serif;
        }
    }

    .hsr-time-details{
        background: #f7f7f7;
        padding: 2rem 1rem;
    }
}

@media (max-width:548px) {
    .hsr-normal{
        display: none;
    }
    
}