.hsr-top{
    margin-top: 10vh;
    .hsr-overlay{
        height: 30vh;
        width: 100%;
        background-color: rgba(0,0,0,0.7);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        h1{
            font-size: 2rem;
            font-weight: 800;
            letter-spacing: 3px;
            text-transform: uppercase;
            text-align: center;
            color: white;
        }
        p{
            font-size: 0.9rem;
            text-align: center;
            color: #DDDDDD;
            margin-bottom: 0;
        }

        .breadcrumb-item{
            font-size: 0.8rem;
            color: #BFBFBF !important;
            a{
                color: #BFBFBF;
                text-decoration: none;
            }
        }

        .breadcrumb-item:before{
            font-size: 0.8rem;
            color: #BFBFBF !important;
        }
    }
}