.hsr-registration{
.nav-tabs {
    border: none;
}

.nav-link,
.nav-link:hover {
    background: var(--theme-orange);
    color: white;
    text-align: left;
    padding: 0.85rem 1.3rem !important;
    border-radius: 0 !important;
    border: none !important;
    font-family: 'Montserrat', sans-serif;

    .hsr-day {
        font-size: 1rem;
        font-weight: 700;
    }

    .hsr-date {
        font-size: 0.7rem;
        font-weight: 500;
    }
}

button.active {
    background: var(--theme-color) !important;
    color: white !important;
    text-align: left;
    padding: 0.85rem 1.3rem !important;
    border-radius: 0 !important;
    border: none !important;
    font-family: 'Montserrat', sans-serif;
}
.tab-content {
    background: #f7f7f7;
    padding: 2rem;
}

label{
    font-size: 0.9rem;
    font-weight: 600;
    font-family: 'Montserrat';
}

.form-control{
    font-size: 0.9rem;
    border-radius:3px;
}

.input-group-text{
    font-size: 0.9rem;
    font-family: 'Montserrat'; 
}

.form-control:focus{
    box-shadow:none;
}

.form-select:focus{
    box-shadow: none;
}

.form-check-input:focus{
    box-shadow:none;
}
}

.errorMsg {
    max-width: 350px;
    color: #f21e08;
    padding: 2px 0;
    margin-top: 2px;
    font-size: 14px;
    font-weight: 300;
  }