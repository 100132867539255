.hsr-prize-card{
    overflow: hidden;
    border:none;
    border-radius:0;
    display: block;
    .card-header{
        background: #E9E9E9;
        border:0;
        border-radius:0;
        overflow: hidden;
        h3{
            font-size: 1.3rem;
            text-align: center;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: 3px;
            margin-bottom: 0;
            font-family: 'Montserrat';
        }
        .hsr-certificate{
            background: var(--theme-orange);
font-size: 8px;
color: #fff;
margin-top: 3px;
margin-bottom: 0;
transform: rotate(45deg);
position: absolute;
right: -30px;
top: 20px;
padding: 5px 26.5px;
font-weight: 600;
text-align: center;
text-transform: uppercase;
letter-spacing: 1px;
        }
    }

    .card-footer{
        background:var(--theme-orange);
        border:0;
        border-radius:0;
        h3{
            font-size: 1.5rem;
            text-align: center;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: 3px;
            color:white;
            font-family: 'Montserrat';
            margin-bottom: 0;
        }
    }
}